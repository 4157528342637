@import "/src/styles/variables";

.avatar {
  width: 160px !important;
  height: 160px;
  border: 2px solid #E9ECEF;
  border-radius: 50%;
  position: static !important;
  z-index: 1 !important;
}

.editAvatar {
  position: absolute !important;
  width: 38px;
  height: 38px;
  top: 110px !important;
  right: 0;
  background-color: #F8F9FA !important;
  z-index: 2 !important;
}

@media (max-width: 767px) {
  .doctorHeaderName {
    font-size: 29px !important;
  }
}

.button {
  width: 136px !important;
}

@media (max-width: 991.98px) {
  .button {
    width: 100% !important;
  }
}

.statusBadge {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
