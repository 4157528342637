@import "/src/styles/variables";

.sentMessage,
.recievedMessage {
  border-radius: 4px;
  padding: 7px 13px;
  height: fit-content;
  max-width: 90% !important;
  min-width: 80% !important;
  margin-bottom: 24px;

  a {
    color: $primary !important;
  }
}

.sentMessage {
  border: 1px solid var(--natural-light-gray, #e9ecef);
  background: var(--natural-white, #fff);
  margin-left: auto;
}

.recievedMessage {
  background: #e9ecef;
  margin-right: auto;
}

.recievedMessage:last-child,
.sentMessage:last-child {
  margin-bottom: 0;
}

.recievedMessage:first-child,
.sentMessage:first-child {
  margin-top: auto;
}

.avatarImg {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #e9ecef;
}

.tooltip {
  z-index: 999999 !important;
  border: 1px solid var(--natural-gray, #adb5bd);
  border-radius: 4px;
  width: 241px;

  opacity: 1 !important;
  background-color: white !important;

  --bs-tooltip-padding-y: 0;
  --bs-tooltip-padding-x: 0;
  --bs-tooltip-bg: white !important;
  --bs-tooltip-opacity: 1 !important;
}

.tooltip div:nth-child(2) {
  background-color: white !important;
}

.tooltip .tooltip-arrow {
  display: none !important;
  z-index: 0;
}

.tooltipLink {
  margin: 13px;
  text-align: start;

  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}
