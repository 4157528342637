@import "/src/styles/variables";

.modalImage {
  display: block;
  margin: 0 auto 16px;
  max-width: 100%;
  max-height: 90%;
  max-height: calc(100% - 42px);
  background-image: linear-gradient(
      45deg,
      #c3c4c7 25%,
      transparent 25%,
      transparent 75%,
      #c3c4c7 75%,
      #c3c4c7
    ),
    linear-gradient(
      45deg,
      #c3c4c7 25%,
      transparent 25%,
      transparent 75%,
      #c3c4c7 75%,
      #c3c4c7
    );
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

.modalMenu {
  width: 30%;
  border-left: 1px solid $disabled;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 576px) {
    width: 100%;
  }
}
