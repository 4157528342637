@media (max-width: 991.98px) {
  .title {
    font-size: 16px !important;
  }
}

@media (max-width: 991.98px) {
  .button {
    width: 100%;
  }
}