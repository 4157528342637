.CECardContainer
{
    padding: 12px;
    border: 1px solid #E9ECEF;
    border-radius: 4px;
}

.typeHeader
{
    font-weight: 600;
    color: #ADB5BD;
    line-height: 130%;
    text-transform: capitalize;
    font-size: 12px;
}

.titleHeader
{
font-weight: 600;
font-size: 16px;
line-height: 130%;
letter-spacing: 0.02em;
color: #003B50;
}

.datesContainer
{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 130%;
color: #6C757D;
margin-right: 10px;
display: flex;
}