.breadcrumbList {
  padding: 0;
  margin: 0;
  display: flex;

  li:not(:last-child):after {
    content: " |";
  }
}

.breadcrumbListItem {
  list-style: none;
}
