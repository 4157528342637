.accordionButton {
  background: none;
  border: none;
  display: inline;
  width: 18px;
  padding: 0;
}
.lang {
  max-width: 27px;
  height: 24px;
}
.chevronColor {
  color: #11c0f2;
}
.textareaInput {
  width: 337px;
  height: 38px;
}
.inputWidth {
  width: 100%;
  max-width: 337px;
}
