// colors
$primary: #11c0f2;
$secondary: #ed2369;
$success: #28dfae;
$warning: #fe6f4f;
$dark: #003b50;
$dark-2: #00739c;
$gray: #e9ecef;
$light: #f8f9fa;
$background: #f1f1f1;
$disabled: #cccccc;
$natural-gray: #adb5bd;
