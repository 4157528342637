@import "styles/variables";

.button {
  & > button {
    height: 40px;
    width: 40px;
  }
}

.nameLabel {
  min-width: 200px;
}

.arrowUp {
  svg {
    transform: rotate(270deg);
    transition: transform 200ms;
  }
}

.arrowDown {
  svg {
    transform: rotate(90deg);
    transition: transform 200ms;
  }
}

.radius {
  border-radius: 4px;
}