.urlContentWrapper {
  background: #f8f9fa;
  .urlContentTitle {
    font-size: 12px;
    letter-spacing: 0.57px;
  }
}

.twitterWrapper{
  div{
    width: 100%;
    display: flex;
    justify-content: center;
    div{
      width: 100%;
      iframe{
        width: 100%;
      }
    }
  }
}

.YoutubeWidgetWrapper{
  height: 400px;
}

.contentImage {
  width: 100%;
  position: relative;
  .contentImageCloseButton {
    position: absolute;
    top: -19px;
    right: 10px;
    width: 38px;
    height: 38px;
    background: white;
    padding: 10px;
    z-index: 20;
  }
  img {
    object-fit: cover !important;
    object-position: center !important;
    width: 100%;
    height: 400px;
    border-radius: 4px 4px 0 0;
  }
}
.urlImage {
  img {
    height: 300px;
  }
}
