.avatarImg {
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #e9ecef;
}
.header {
  border-bottom: 1px solid var(--natural-light-gray, #e9ecef);
}
.backBtn {
  background: none;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
}
