.inputContainer {
  position: relative;
  width: 100%;
}

.inputField {
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.inputField:disabled {
  background-color: #f5f5f5;
}

.calendarIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  pointer-events: none;
}

@media (min-width: 992px) {
  .inputContainer {
    max-width: 255px;
  }
}
