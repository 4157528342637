.MediaPickerContainer {
  min-height: 90px;
}
.WordpressButton
{
  background-color: #f0f0f0;
  border-radius: 2px;
  line-height: 20px;
  min-height: 90px;
  padding: 8px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
}
