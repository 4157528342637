.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
:not(.btn-link) + .btn-link:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: #003b50 !important;
  border-color: #003b50 !important;
}

.btn.show path {
  fill: white !important;
  stroke: white !important;
}

.btn.show:hover path {
  fill: white !important;
  stroke: white !important;
}

.dropdown-toggle::after {
  display: none !important;
}
