.card {
  height: 120px;
}

.title {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px; // Wysokość białego rozmycia na dole
    background: linear-gradient(transparent, white); // Rozmycie białe na dole
    pointer-events: none;
  }
}

.titleHeight {
  height: 65px;
}