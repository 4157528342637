.head {
  background: white;
}
.container {
  max-width: 1140px;
  margin-inline: auto;
}
.logo {
  height: 34px;
}
.krs {
  color: #e96e00 !important;
}
