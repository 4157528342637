.wrapper {
  border: 1px solid #E9ECEF;
  border-radius: 4px;
}

.badge {
  background-color: #E9ECEF !important;
}

.warningBadge {
  background-color: #fe6f4f !important;
}

.disactiveBadge {
  background-color:#E9ECEF !important;
}

.underWrapper {
  border-bottom: 1px solid #E9ECEF;
  border-top: 1px solid #E9ECEF;
  border-radius: 4px;

  @media (min-width: 768px) {
    border-bottom: none;
    border-top: none;
  }
}


.button {
  width: 400px;
  &> button {
    height: 40px;
    width: 40px;
  }
}

.buttonSize {
  height: 40px;
  width: 40px;
}

.nameLabel {
  min-width: 200px;
}