.card {
  height: 520px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.cardList {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.image {
  border-radius: 4px;
  width: 100%;
  height: 225px;
  object-fit: cover;
}

.article {

  & > div {
    @media (max-width: 991px) {
      border-top: 2px solid #e9ecef;
    }
    &:not(:last-child) {
      @media (min-width: 992px) {
        border-right: 2px solid #e9ecef;
      }
    }
  }
}