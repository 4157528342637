.readIndicator {
  color: #adb5bd;
  font-weight: 500;
  position: relative;
  .readIndicatorText {
    background: white;
    z-index: 15;
    position: relative;
    padding: 0 16px;
  }
  .readIndicatorLine {
    width: 100%;
    height: 1px;
    background: #e9ecef;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
  }
}

.offcanvas-body {
  width: auto !important;
  height: auto !important;
}
.fitContent {
  height: fit-content !important;
}
