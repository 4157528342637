@import "/src/styles/variables";

.buttonCarousel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.item {
  min-height: 200px;
}

.title {
  @media (max-width: 991.98px) {
    font-size: 16px;
  }
}