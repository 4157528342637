.videoContainer{
section {
  max-height: 60vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  #video {
    max-height: 100%;
    width: auto;
  }
}
}