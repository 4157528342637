.inputCheckBox {
  opacity: 1;

  &:checked {
    background-color: #ADB5BD !important;
    border-color: transparent !important;
    background-image: url('data:image/svg+xml,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4.33333L3.46154 7L9 1" stroke="white" stroke-linejoin="round"/></svg>') !important;
    background-size: 10px 8px;

    &:disabled {
      background-color: #ADB5BD !important;
      opacity: 1;
    }
  }
}