.chatUserAvatar {
  img {
    width: 36px;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #e9ecef;
  }
}
.timer {
  max-width: 25%;
}
