@import '../../../../../styles/variables';


.resultContainer{
  border: 1px solid $gray;
}

.nearestExaminationContainer{
  color: $dark;
  span{
    color: white !important;
    background-color: $primary;
    border-radius: 4px;
  }
}

.actionButton{
  a{
    &:hover{
      background: $secondary !important;
      color: white !important;
    }
  }
}

.rwdButton{
  overflow: initial !important;
  text-overflow: initial !important;
}


