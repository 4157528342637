.commentInputAvatar{
    padding: 0 !important;
    margin: 0 !important;
    img{
        width: 35px !important;
        height: auto;
        border-radius: 50%;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.commentInput{
    textarea{
        height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2))
    }
}

.controlError{
    color: #dc3545;
}