@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.avatar {
  width: 160px !important;
  height: 160px;
  border: 2px solid #e9ecef;
  border-radius: 50%;
}

.editAvatar {
  position: absolute;
  top: 110px;
  right: 0;
  background-color: #f8f9fa !important;
}

.statElem {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #adb5bd;
}

.accountStats {
  border-left: 1px solid #e9ecef;
  @media (max-width: 991.98px) {
    border-left: 0;
    border-top: 1px solid #e9ecef;
  }
  @media (min-width: 992.98px) {
    border-top: 0 !important;
  }
}

@include media-breakpoint-down(md) {
  .avatar {
    width: 100px !important;
    height: 100px;
  }
}

.title {
  @media (max-width: 991.98px) {
    font-size: 16px !important
  }
}

.paragraph {
  @media (max-width: 991.98px) {
    font-size: 16px !important
  }
}