@import "/src/styles/variables";

.dropzoneWrapper {
  position: relative;
  margin-bottom: 3.6rem;
  width: 100%;
  overflow: hidden;
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  border: 4px dashed $disabled;
  justify-content: center;

  &[data-dragactive="true"] {
    border: 4px dashed $primary;
  }
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
}
