.backBtn {
  background: none;
  border: none;
  padding: 0;
  width: 24px;
  height: 24px;
}
.header {
  border-bottom: 1px solid #e9ecef;
}
.topicBtn {
  padding: 16px;
  background: none;
  border-radius: 4px;
  border: 2px solid #e9ecef;
  color: #003b50 !important;
  text-align: left;
}
.disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
