.card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 600px;
}

.img {
  width: 100%;
  height: 207px;
  object-fit: cover;
}

.titleAfter {
  position: relative;

  &::after {
    position: absolute;
    bottom: -25px;
    left: 0;
    display: block;
    width: 36px;
    height: 2px;
    background-color: #e9ecef;
    content: "";
  }
}

@media (max-width: 991.98px) {
  .titleAfter {
    font-size: 16px !important;
    letter-spacing: 0.48px !important;
  }
}
