@import "../../../styles/_variables.scss";

.container {
  background-color: $light !important;
  padding: 60px !important;
}

  @media (max-width: 767px) {
    .container {
      background-color: $light !important;;
      padding: 10px !important;
    }
  }

.containerWidget {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@media (max-width: 767px) {
.button {
  width: 100% !important;
  text-wrap: wrap !important;
}
}

.containerNoEntry {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
}