.addBtn {
  background: none;
  border: none;
  width: 38px !important;
  height: 38px;
}
.sendBtn {
  background: none;
  border: none;
  width: 38px !important;
  height: 38px;
  padding: 7px;
}
.inputArea {
  border: 1px solid #adb5bd;
  border-radius: 4px;
}
.inputAreaActive {
  border: 1px solid #11c0f2;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 2px #11c0f24d;
}
.attachmentsDisplay {
  padding: 6px 12px !important;
  max-height: calc(24px * 5) !important;
  overflow-y: auto;
}

.chatInput {
  border-top: 1px solid var(--natural-light-gray, #e9ecef);
  align-items: end;
}
.close {
  width: 24px;
  height: auto;
  stroke: #11c0f2;

  &:hover {
    cursor: pointer;
  }
}
.textarea {
  resize: none;
  overflow-y: auto;
  height: 38px;
  max-height: 321px;
  line-height: 24px;
  padding: 6px 12px !important;

  &:focus {
    border-top: 1px solid #11c0f2 !important;
  }

  &--selectedFiles {
    border: none;
    border-top: 1px solid #adb5bd;
    border-radius: 0 0 4px 4px;
  }

  &--noSelectedFiles {
    border: none;
  }
}
