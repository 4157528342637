@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
$enable-dark-mode: false;
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/bootstrap";
@import "mixins";

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.border-gray {
  border-color: #e9ecef;
}

.modal-backdrop {
  padding: 3rem;
}

.btn-alivia {
  padding: 7px;
  border-width: 2px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
}

.btn-close {
  color: #003b50 !important;
}

.btn-outline-blue {
  border-color: #0084ff !important;
  color: #0084ff !important;
  background-color: transparent !important;

  &:hover {
    color: #003f7a !important;
    background-color: #f3f3f3 !important;
    border-color: #003f7a !important;
  }

  &.btn-active {
    color: #f3f3f3 !important;
    background-color: #003f7a !important;
    border-color: rgb(0, 63, 122) !important;
  }
}

.btn-outline-light {
  color: #003b50 !important;
  border: 2px solid #e9ecef !important;
  background-color: transparent !important;
  font-weight: 500 !important;

  &:hover {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }

  &.btn-active {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }
}

.btn-outline-grey {
  color: #003b50 !important;
  border: 2px solid #adb5bd !important;
  background-color: transparent !important;

  &:hover {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }

  &.btn-active {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }
}

.btn-outline-primary {
  border-color: #11c0f2 !important;
  color: #11c0f2 !important;
  background-color: transparent;
  border-width: 2px;

  &:hover {
    color: #fff !important;
    background-color: #11c0f2 !important;
    border-color: #11c0f2 !important;
  }

  &.btn-active {
    color: #fff !important;
    background-color: #11c0f2 !important;
    border-color: #11c0f2 !important;
  }

  &.show {
    color: #fff !important;
  }
}

.btn-disabled {
  color: #adb5bd !important;
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;

  &:disabled {
    opacity: 1;
    color: #adb5bd !important;
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
  }
}

.btn-outline-dark-blue {
  color: #003b50 !important;
  border: 1px solid #e9ecef !important;
  background-color: transparent !important;

  &:hover {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }

  &.btn-active {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }
}

.btn-transparent {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }

  &.btn-active {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    border-color: transparent;
  }
}

.bg-gray {
  background-color: #e9ecef;
  color: #6c757d;
}

.w-65 {
  width: 65%;
}

.h-100 {
  height: 100%;
}

.pe-12 {
  padding-right: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.ms-12 {
  margin-left: 12px;
}

.me-12 {
  margin-right: 12px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-29 {
  font-size: 29px;
}

.fs-39 {
  font-size: 39px;
}

.fw-medium {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

.active {
  font-weight: 700;
}

.h-auto {
  height: auto;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-150 {
  line-height: 1.5;
}

.ls-1 {
  letter-spacing: 0.01em;
}

.ls-2 {
  letter-spacing: 0.02em;
}

.ls-3 {
  letter-spacing: 0.03em;
}

.ls-4 {
  letter-spacing: 0.04em;
}

.ls-5 {
  letter-spacing: 0.05em;
}

.ls-6 {
  letter-spacing: 0.06em;
}

.text-blue {
  color: #0084ff !important;
}

.text-grey {
  color: #6c757d !important;
}

.text-grey-2 {
  color: #adb5bd !important;
}

.text-dark-blue {
  color: #003b50 !important;
}

.text-validation {
  color: #ed2369 !important;
}
.is-invalid {
  border-color: #ed2369 !important;
}

.text-primary {
  color: #11c0f2 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-transform-none {
  text-transform: none !important;
}

.bg-dark-blue {
  background-color: #003b50 !important;
}

.bg-secondary {
  background-color: #ed2369 !important;
}

.border-dark-blue {
  border-color: #003b50 !important;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

.ta-resize-none {
  resize: none;
}

.offcanvas-body {
  overflow-y: clip;
  padding: 0;
}

.offcanvas-sm {
  width: 240px !important;
  background-color: #f8f9fa !important;
  border-right: 2px solid #e9ecef;
}

.btn-primary {
  color: #fff;

  &:hover {
    color: #fff !important;
  }
}

.border-rstCounter {
  border-right: 2px solid #e9ecef;
}

.cursor-pointer {
  cursor: pointer;
}

.containerWithShadow {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.btn {
  white-space: nowrap; /* Zapobiega zawijaniu tekstu do nowej linii */
  text-transform: uppercase; /* Zmienia tekst na duże litery */
  //overflow: hidden; /* Ukrywa nadmiarowy tekst */
  //text-overflow: ellipsis; /* Dodaje "..." na końcu, jeśli tekst jest zbyt długi */
  border-radius: 4px;
  border-width: 2px;
  stroke-width: 0px;
}

.badge {
  border-radius: 4px;
}

.form-control {
  --bs-border-width: 1px;
  --bs-border-color: #ccc;
  border-radius: 4px;
  &:focus {
    box-shadow: none !important;
  }
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.accordion {
  border-radius: 4px;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23003B50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  --bs-accordion-btn-icon-width: 1.25rem !important;

  .accordion-header {
    background: white !important;
    button {
      font-weight: 600;
      color: #003b50;
      background: white !important;
      position: relative !important;
      &::before {
        content: "";
        margin-left: auto;
        position: absolute;
        width: 2rem;
        height: 2rem;
        background-color: white;
        right: 0.875rem;
        border-radius: 5px;
      }
      &::after {
        z-index: 200;
        position: relative;
      }
    }
    button:not(.collapsed) {
      font-weight: 600;
      color: #003b50;
      background: white !important;
      position: relative !important;
      &::before {
        content: "";
        margin-left: auto;
        position: absolute;
        width: 2rem;
        height: 2rem;
        background-color: #003b50;
        right: 0.875rem;
        border-radius: 5px;
      }
      &::after {
        z-index: 200;
        position: relative;
      }
    }
  }
}

.modal-content {
  border-radius: 4px;
}

.img {
  border-radius: 4px;
}

.alivia-radius {
  border-radius: 4px;
}

.btn-square-alivia {
  @include square(38px);
}

.popoverAlivia {
  display: flex;
  --bs-popover-body-padding-y: 3px;
  --bs-popover-body-padding-x: 7px;
  --bs-popover-bg: #e9ecef;
  --bs-popover-border-radius: 4px;
  --bs-popover-border-color: #e9ecef;
}

.mobileTooltip {
  max-width: 125px !important;
}

.linkAlivia {
  color: #003b50 !important;
  text-decoration-line: underline !important;
  transition: color 0.3s !important;

  &:hover {
    color: #003b50 !important;
  }

  &:visited {
    color: #003b50 !important;
  }

  &:active {
    color: #003b50 !important;
  }
}

.stroke-dark-blue {
  stroke: #003b50;
  stroke-width: 0.5px !important;
}

.stroke-white {
  stroke: #fff;
  stroke-width: 0.5px !important;
}

.stroke-primary {
  stroke: #11c0f2;
  stroke-width: 0.5px !important;
  &:hover {
    stroke: #fff !important;
  }
}

.tooltip {
  --bs-tooltip-font-size: 14px !important;
  --bs-tooltip-color: #003b50 !important;
  --bs-tooltip-bg: #e9ecef !important;
}

.border-natural-gray {
  border-color: #adb5bd;
}

.natural-gray {
  color: #adb5bd;
}

.navbar-toggler {
  border: none;
  outline: none;

  &:focus,
  &:active,
  &-icon:focus {
    outline: none;
    box-shadow: none;
  }
}
