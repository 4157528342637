.active {
  animation: onEnter 3000ms linear;
}
.button {
  position: fixed;
  bottom: 18px;
  right: 18px;
  padding: 12px !important;
  border-radius: 72px !important;
  background: var(--colors-secondary, #ed2369) !important;
  width: 184px !important;
  height: 48px !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none !important;
  z-index: 1110;

  transform: translateX(155px);
  transition: transform 0.15s linear !important;

  &:hover {
    transform: translateX(0);
  }

  & svg {
    stroke: #ffffff;
    fill: #ffffff;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    bottom: 36px;
    right: 20px;
  }
}

@keyframes onEnter {
  from {
    transform: translateX(80%);
  }
  20% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(0);
  }
  to {
    transform: translateX(80%);
  }
}
