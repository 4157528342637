@import "styles/variables";

.button {
  & > button {
    height: 40px;
    width: 40px;
  }
}
.nameLabel {
  min-width: 200px;
}

.arrowUp {
  svg {
    transform: rotate(270deg);
    transition: transform 200ms;
  }
}

.arrowDown {
  svg {
    transform: rotate(90deg);
    transition: transform 200ms;
  }
}

.radius {
  border-radius: 4px;
}

.rotate {
  transform: rotate(45deg);
}

.progressBar {
  height: 8px !important;
  //background-color: red;
}

.OpinionsCounter {

  @media (min-width: 992px) {
    transform: translate(0, calc(50% - 4px));
  }
}

.PercentIndicatorUpper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 4px 12px !important;
  gap: 10px !important;
  position: absolute !important;
  width: 65px !important;
  height: 29px !important;
  border-radius: 4px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  color: #fff !important;
}

.nowrapLg {
  white-space: nowrap;
  @media (max-width: 992px) {
    white-space: normal;
  }
}

.userName {
  letter-spacing: 0.36px;
}

.collectionName {
  letter-spacing: 0.66px;
  @media (max-width: 991.98px) {
    letter-spacing: 0.48px;
    font-size: 16px !important;
  }
}

.analiticsTitle {
  letter-spacing: 0.72px;
}

.dataNumbers {
  letter-spacing: 0.48px;
}