.wrapper
{
    border: 1px solid #E9ECEF;
    border-radius: 4px;
}

@media (max-width: 991.98px) {
    .title {
        font-size: 16px !important;
    }
}


