@import "styles/mixins";
@import "styles/variables";

.image {
  @include square(36px);
}

.dangerHover {
  color: #6C757D;
  &:hover {
    color: $dark;
  }
}

.info {
  @include square(15px);
  padding: 1px;
}

.commentReplyAction {
  width: fit-content !important;
}

.ratingBorder {
  @media (max-width: 991.98px) {
    border: none !important;
  }
}