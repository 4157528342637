.InfoBarHeaderSmall {
  font-size: 13px;
  font-weight: 600;
  color: #adb5bd;
}
.eventLink {
  &:hover {
    path {
      fill: white !important;
    }
    span {
      color: white;
    }
    svg {
      path {
        fill: white !important;
      }
    }
  }
}
