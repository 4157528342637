.avatar
{
padding: 0px;
width: 38px;
height: 38px;
border: 2px solid #E9ECEF;
border-radius: 50%;
margin-right: 24px;;
}

.wrapper
{
    border: 1px solid #E9ECEF;
    border-radius: 4px;
}

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2px 4px !important;
    font-weight: 500 !important;
    letter-spacing: 0.72px;
}

.button {
    @media (max-width: 991.98px) {
        width: 100% !important;
    }
}