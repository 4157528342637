.previews {
  gap: 10px;
  align-items: flex-end;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.previewCard {
  max-width: 200px;
}
.previewCard img {
  max-width: 100%;
}
.previewCard p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
