@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@include media-breakpoint-down(sm) {
  .headerText {
    font-size: 22px;
  }
  .headerTextProfile {
    font-size: 29px;
  }
  .infoContainer
  {
    width: 100% !important;
  }
}

@include media-breakpoint-up(sm) {
  .headerText {
    font-size: 39px;
  }
  .headerTextProfile {
    font-size: 39px;
  }
}

@include media-breakpoint-up(md) {
    .profileHeaderContainer {
      padding-right: 48px !important;
      padding-left: 48px !important;
    }
  }

  @include media-breakpoint-down(md) {
    .profileHeaderContainer {
      padding-right: 4px !important;
      padding-left: 4px !important;
    }
  }

  .guardianMargin
  {
    margin-right: 12px !important;
  }
  
.badge {
  font-weight: 600 !important;
  font-size: 12px !important;
  padding: 0 5px !important;
  line-height: 150% !important;
  letter-spacing: 0.72px;
}