.InputIcon
{
    position: absolute;
    right: 0;
    top: 50%;
}

.labelMargin
{
    margin-bottom: 6px !important;
}

.InputStyle
{
    min-height: 38px !important;
    border: 1px solid #ccc !important;
}