.breadcrumbLink {
  padding: 1px 6px;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: #11c0f2 !important;
  background-color: transparent;

  &:hover {
    color: #29c6f3 !important;
  }
}
