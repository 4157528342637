.card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.img {
  width: 225px;
  height: 100%;
  object-fit: cover;
}

.titleAfter {
  position: relative;
}

.EventInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #6c757d;
  flex: none;
  order: 0;
  flex-grow: 0;
  border-right: 2px solid #E9ECEF;
  padding: 0 12px;
}

.first {
  padding-right: 12px;
  padding-left: 0;
}

.last {
    padding-left: 12px;
  border-right: none !important;
}

.ReadMoreButton
{
  font-weight: 500!important;
  font-size: 16px !important;
  border-width: 2px !important;
}

@media (max-width: 767.98px) {
  .ReadMoreButton {
    width: 100%;
  }
  .MobileCategory {
    text-align: right;
  }
}
