@import "/src/styles/index.scss";

.navHeader {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.96px !important;
  font-style: normal !important;
}

.hrLine {
  border: 1px $dark solid !important;
  opacity: 1 !important;
}
