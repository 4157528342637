.imageWrapper {
  width: 150px;
  height: 150px;
  outline: 1px solid #f0f0f1;
  box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
  background: #f0f0f1;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
}
