.noHistory,
.messages,
.chatBody {
  height: 100%;
}

.chatBodyMessenger {
  min-height: 600px;
  max-height: calc(90% - 87px) !important;
}

.messages {
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #003b50;
    border-radius: 10px;
  }
}

.readIndicator {
  color: #adb5bd;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  margin-bottom: 24px;
  .readIndicatorText {
    background: white;
    z-index: 15;
    position: relative;
    padding: 0 16px;
  }
  .readIndicatorLine {
    width: 100%;
    height: 1px;
    background: #e9ecef;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
  }
}
