.InfoBarHeaderSmall {
  font-size: 13px;
  font-weight: 600;
  color: #adb5bd;
}

.loading {
  span {
    display: block;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    border: 3px solid;
    border-color: #000;
    border-top-color: transparent;
    animation: loading infinite ease-in-out 1s;
  }
}

.eventLink:hover {
    color: #fff !important;
  }

  .noDescriptionWrapper
{
    border: 1px solid #E9ECEF;
    border-radius: 4px;
}

.eventPagePadding
{
  padding-left: 60px !important;
}

.pollMarginRemoval
{
  margin-top: -48px !important;
}
