.header {
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #003B50;
}

.accordion {
  padding: 0 !important;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  --bs-accordion-btn-focus-border-color: #e9ecef;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
  --bs-accordion-active-color: #003b50;
  --bs-accordion-active-bg: #f8f9fa;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}

.resetButton {
  border: 1px solid #e0e0e0 !important;
}

@media (max-width: 991.98px) {
  .resetButton {
    width: 100% !important;
  }
}

@media (max-width: 991.98px) {
  .submitButton {
    width: 100% !important;
  }
}