.button {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  color: #8d8d8d;
  background-color: transparent;
  line-height: normal;
}
