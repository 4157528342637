@import "/src/styles/variables";

.dropzoneWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(0, 59, 80)' stroke-width='1' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  border-image-slice: 20;
  justify-content: center;

  &[data-dragactive="true"] {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(17, 192, 242)' stroke-width='1' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
  }
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
}