@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.AccountSettingsWidget {
  display: flex;
  flex-direction: row;
  margin-top: 3rem !important;
  background: #ffffff;
  padding: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@include media-breakpoint-up(sm) {
  .AccountSettingsWidget {
    max-width: 712px;
  }
  .AccountSettingsPage {
    background-color: #f8f9fa;
    h1 {
      max-width: 712px;
      margin: 0 auto;
      font-size: 39px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .AccountSettingsPage {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .AccountSettingsPage {
    background-color: #f8f9fa;
    h1 {
      max-width: 712px;
      margin: 0 auto;
      font-size: 29px;
    }
  }
}

.title {
  @media (max-width: 991.98px) {
    font-size: 16px !important;
    letter-spacing: 0.48px;
    line-height: 130%;
  }
}
