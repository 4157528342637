@import "styles/variables";

.slider {
  &::-webkit-slider-thumb {
    background: url('data:image/svg+xml,<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="16" rx="4" fill="%23003B50"/><path d="M8 4V12" stroke="white" stroke-linecap="round"/><path d="M12 4V12" stroke="white" stroke-linecap="round"/><path d="M16 4V12" stroke="white" stroke-linecap="round"/></svg>') !important;
    width: 24px !important;
    height: 16px !important;
    border-radius: 4px !important;
  }
  &::-moz-range-thumb {
    background: url('data:image/svg+xml,<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="16" rx="4" fill="%23003B50"/><path d="M8 4V12" stroke="white" stroke-linecap="round"/><path d="M12 4V12" stroke="white" stroke-linecap="round"/><path d="M16 4V12" stroke="white" stroke-linecap="round"/></svg>') !important;
    width: 24px !important;
    height: 16px !important;
    border-radius: 4px !important;
  }
  &::-ms-thumb {
    background: url('data:image/svg+xml,<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="16" rx="4" fill="%23003B50"/><path d="M8 4V12" stroke="white" stroke-linecap="round"/><path d="M12 4V12" stroke="white" stroke-linecap="round"/><path d="M16 4V12" stroke="white" stroke-linecap="round"/></svg>') !important;
    width: 24px !important;
    height: 16px !important;
    border-radius: 4px !important;
  }
}

.labelAvatar {
  margin: 2px;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(0, 59, 80)' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  border-image-slice: 20;

  &[data-dragactive="true"] {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(17, 192, 242)' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
  }
}

.labelFile {
  box-sizing: border-box;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(0, 59, 80)' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  border-image-slice: 20;

  &[data-dragactive="true"] {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(17, 192, 242)' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
  }
}

.breakLine {
  word-break: break-all;
}