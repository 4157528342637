@import "src/styles/variables";

.section {
  padding: 48px 60px;
}
@media (max-width: 991.98px) {
  .section {
    padding: 48px 30px;
  }
}
@media (max-width: 767.98px) {
  .section {
    padding: 20px 10px;
  }
}
@media (max-width: 575.98px) {
  .section {
    padding: 20px 14px;
  }
}


.title {
  font-size: 39px !important;
  line-height: 130% !important;
  letter-spacing: 1.17px !important;
}

@media (max-width: 991.98px) {
  .title {
    font-size: 29px !important;
    letter-spacing: 0.87px !important;
  }

}

.noEventsContainer {
  border: 1px solid #E9ECEF;
  padding: 120px;
  margin-top: 48px !important;
}

.noEventsHeader {
  font-weight: 600;
  font-size: 19px;
  color: #6C757D;
}

.book {
  color: $primary;
}

.container {

}
