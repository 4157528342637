.section {
  padding: 48px 60px;

  @media (max-width: 480px) {
    padding: 48px 16px;
  }
}
.feedAddPost {
  background: white;
  box-shadow: 0 13px 5px rgba(0, 0, 0, 0.01), 0 8px 5px rgba(0, 0, 0, 0.02),
    0 3px 3px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.04),
    0 0 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 24px 0;
  justify-content: center;
}

.loading {
  span {
    display: block;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    border: 3px solid;
    border-color: #003b50;
    border-top-color: transparent;
    animation: loading infinite ease-in-out 1s;
  }
}

.showOlderButton {
  text-decoration: underline;
  cursor: pointer;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.rowHeight {
  height: 327px;
}

@media (max-width: 768px){

  .resultsContainer{
    div{
      border-right: none !important;
      &:not(:last-child){
        border-bottom: 2px solid #E9ECEF;
      }
    }
  }
}