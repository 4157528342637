@import "/src/styles/variables";

.commentAuthorAvatar {
  padding: 0 !important;
  margin: 0 !important;

  img {
    width: 35px !important;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
}

.commentAuthorNameAndContent {
  color: $dark;
}

.commentReplyContent {
  & a {
    &:hover {
      color: $primary !important;
    }
  }

  @media (max-width: 991.98px) {
    font-size: 14px !important;
  }
}

.commentAuthorName {
  font-weight: 600;
}
.commentReplyActionContainer {
  color: #6c757d;
  font-size: 12px;
}

.commentReplyAction {
  width: fit-content !important;
}

.replyInput {
  textarea {
    width: 100% !important;
  }
}

@keyframes mark {
  to {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 5px;
    border: 2px solid #6c757d;
  }
}

.scale {
  animation: mark 0.5s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
