@import "src/styles/variables";

.accordinItems:not(:first-of-type) {
  border-top: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color) !important;
}

.accordinItems {
  a {
    color: $primary !important;
  }
}

.accordionHeader {
  & button::after {
    z-index: 1 !important;
  }
}

@media (max-width: 991.98px) {
  .title {
    font-size: 16px !important;
  }
}
