@import "../../../styles/variables";

.offcanvas {
  height: 100% !important;
  width: 100% !important;
  z-index: 9999 !important;

  @media (min-width: 576px) {
    height: calc(100% - 72px) !important;
    margin-top: 72px;
    width: 496px !important;
    z-index: 1120 !important;
  }
}

.searchInputWrapper {
  background: white;
  border: 1px solid #adb5bd;
  input {
    border: 0;
    outline: 0 !important;
  }
  span {
    path {
      stroke: #adb5bd;
    }
  }
}
.container {
  height: calc(100% - 128px);

  position: relative;
  @media (min-width: 576px) {
    height: 90%;
  }
}
.mobileHeader {
  height: 70px;
}
.close {
  width: 24px;
  height: auto;
}
