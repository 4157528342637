@import "../../../styles/variables";

.searchInputWrapper {
  background: white;
  border: 1px solid #adb5bd;
  input {
    border: 0;
    outline: 0 !important;
  }
  span {
    path {
      stroke: #adb5bd;
    }
  }
}

.noChats {
  color: var(--natural-gray, #adb5bd);
  height: 100%;
}
.container {
  height: calc(100vh - 72px - 164px);
}
.wrapper {
  height: 90%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}
