@media (min-width: 576px) {
  .react-calendar__tile {
    padding: 17.5px;
  }
}
@media (max-width: 575px) {
  .react-calendar__tile {
    padding: 8px !important;
  }
}

.react-calendar {
  border-right: 1px solid #e9ecef;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 17.5px;
  background: none;
  position: relative;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font: inherit;
  font-size: 16px;
  background: none;
}

.react-calendar__navigation__label__labelText {
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #003b50;
  text-transform: capitalize;
}

.react-calendar__navigation {
  padding: 24px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.react-calendar button {
  background: none;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-decoration: none;
  padding-bottom: 18px;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #adb5bd;
}

.react-calendar__viewContainer {
  padding: 0 24px 24px 24px;
}

abbr {
  text-decoration: none !important;
}

.react-calendar__tile abbr {
  position: relative;
  text-decoration: none !important;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.react-calendar__tile--now abbr::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 2px solid #e9ecef;
  border-radius: 20px;
  top: -8px;
  left: -10px;
}

.react-calendar__tile--active abbr::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #003b50;
  border: 2px solid #003b50;
  border-radius: 20px;
  top: -8px;
  left: -10px;
  z-index: -1;
}

.react-calendar__tile--active abbr {
  color: white;
  z-index: 1;
}

.event-holder {
  padding: 28px 24px 0 24px !important;
}

.contains-events abbr::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ed2369;
  display: inline-block;
  position: absolute;
  bottom: -8px;
  left: 40%;
}

.event-cover-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6px;

  & .event-cover {
    border-radius: 5px;
    height: 1.15rem;
    width: 1.15rem;
    object-fit: cover;
    object-position: center;
    margin: 6px;
  }
}