.marginReset ul {
  margin: 0;
}
.filters {
  gap: 15px;
  align-items: flex-end;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
}

.filters input {
  max-height: 36px;
}
.dateFilter {
  gap: 5px;
}
.filters input,
.filters div {
  width: 100%;
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: fit-content;
  }
}
