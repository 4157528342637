@import "styles/variables";

.inputGroup {
  max-width: 150px;

  button {
    height: 38px;
    width: 38px;
    color: $dark;
    margin: 0;
    padding: 0;
    border-color: #adb5bd !important;

    &:disabled {
      color: $dark;
      border-color: #adb5bd !important;
      background: #e9ecef !important;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  input {
    font-size: 16px;
    width: calc(150px - 2 * 38px);
    text-align: center;
    color: $dark;
    border-color: #adb5bd;
    border-left: none;
    border-right: none;
    padding: 0;
    border-radius: 0;

    &:disabled {
      color: $dark;
      border-color: #adb5bd !important;
      background-color: #e9ecef !important;
      //color: red !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.image {
  border-radius: 50%;
  border: 1px solid #e9ecef;
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.buttonEditAvatar {
  background-color: $light !important;
  width: 38px;
  height: 38px;

  &:hover {
    background-color: $primary !important;
  }
}
