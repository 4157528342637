.mainTr {
  border: 1px solid rgb(212, 212, 212);
}

.mainTd {
  & .hoverRow {
    opacity: 0;
  }

  &:hover {
    & .hoverRow {
      opacity: 1;
    }
  }
}

.hoverRow {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.inactiveTd {
  --bs-table-bg: #f1f1f1;
}

.arrowUp {
  transform: rotate(-90deg);
  transition: transform 200ms;
}

.arrowDown {
  transform: rotate(90deg);
  transition: transform 200ms;
}