.custom-radioInput-pink {
  border-color: var(--pink-btn-color) !important;

  &:checked {
    background-color: var(--pink-btn-color) !important;
    border-color: var(--pink-btn-color) !important;
  }

  &:focus {
    box-shadow: 0 0 0 1px #ed236952 !important;
  }
}
