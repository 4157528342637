@import "/src/styles/variables";

.option-separator {
  margin: 0;
  padding: 0;
  text-align: center;  &:hover {
  background: transparent !important; ;
}
}

.separator {
  //width: 50%;
  height: 1px;
  margin: 0;
  padding: 0;
  border: none;
  border-top: 1px solid $gray;
  &:hover {
    background: transparent !important; ;
  }
}
