.inputContainer input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.inputContainer input[type="time"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  background-position: right 10px center;
  background-size: 16px;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
