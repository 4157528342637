.card {
  min-height: 400px !important;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.cardList {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.img {
  width: 100%;
  height: 207px;
  object-fit: cover;
  border-radius: 4px;
}
