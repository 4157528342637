@import "styles/variables";

.input {
  font-size: 16px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px !important;
  border-color: #ADB5BD;
  color: $dark;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
  &::-webkit-input-placeholder {
    text-align: right;
  }

  &:-moz-placeholder {
    text-align: right;
  }

  &::-moz-placeholder {
    text-align: right;
  }

  &:-ms-input-placeholder {
    text-align: right;
  }
}

.content {
  max-width: 750px;
}