@import "/src/styles/index.scss";

.navigation {
  min-width: 240px;
  width: 240px;
  height: 100vh;
  max-height: fit-content;
  position: fixed;
  overflow-y: auto;
  padding-top: 45px;
  padding-bottom: 45px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #11c0f2;
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #e9ecef;
  }

  @media (min-width: 576px) {
    top: 70px;
    padding-bottom: 120px;
  }
}

.active {
  background-color: rgba(17, 192, 242, 0.12) !important;
  color: #11c0f2 !important;
  font-weight: 400 !important;
}

.navigation:hover {
  color: #fff !important;
}

.navLink {
  &:hover {
    & svg {
      color: #fff !important;
    }
  }
}

a {
  background-color: white;
  color: $dark !important;
}

.navLinkPartner--active,
.navLinkPartner--deleted {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.navLinkPartner--active {
  &:hover {
    color: #fff !important;
  }
}

.navLinkPartner--deleted {
  color: #adb5bd;
}

.image {
  width: 21px !important;
  border-radius: 50%;
}

.navLinkContainer {
  color: white !important;
}

.navLinkPartner:hover {
  & .navLinkPartner--active {
    color: #fff;
  }
}

.tiles {
  color: #11c0f2;
  margin-bottom: 1px;
}

.article {
  color: $success;
}

.benefits {
  color: #fe6f4f;
  display: inline-flex;
}

.message {
  color: #11c0f2;
}

.events {
  color: #11c0f2;
}

.logOut {
  color: #11c0f2;
}

.doctor {
  color: #11c0f2;
  width: 20px !important;
  height: 20px !important;
}

.briefcase {
  color: #11c0f2;
}

.arrowFromSquare {
  color: #11c0f2;
}

.notActive {
  font-weight: 400 !important;
}
