.mainTr {
  position: relative;
  border: 1px solid rgb(212, 212, 212);
  border-bottom: none;
  > td {
    padding: 8px 0 0 8px;
    border: none;
  }

  &:hover + .hoverTr {
    td {
      opacity: 1;
    }
  }
}

tr {
  > td {
    width: 300px;
  }
}

.hoverTr {
  background-color: transparent;
  border: 1px solid rgb(212, 212, 212);
  border-top: none;
  > td {
    opacity: 0;
    padding: 0 18px;
    border: none;
  }

  &:hover {
    > td {
      opacity: 1;
    }
  }
}

.collapseTr {
  background-color: rgb(231, 231, 231);
  height: 0px;

  &[data-active="false"] {
    > td {
      padding: 0 8px;
      border: 0;
    }
  }
}
