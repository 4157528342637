@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.avatar {
  border-radius: 50%;
}

.avatarBorder 
{
  border: 2px solid #E9ECEF;
}

.editAvatarButton {
  position: absolute;
  right: 0;
  background-color: #f8f9fa !important;
  top: 71.875%;
  width: 23.75%;
  height: 23.75%;
}

@include media-breakpoint-down(sm) {
  .avatarLg {
    width: 96px !important;
    height: 96px !important;
  }
}

@include media-breakpoint-up(sm) {
  .avatarLg {
    width: 160px !important;
    height: 160px !important;
  }
}

.avatarSm {
  width: 38px !important;
  height: 38px;
}

.avatarLgFixed{
  width: 160px !important;
  height: 160px !important;
}

.avatarXs{
  width: 24px !important;
  height: 24px !important;
}

.avatarMd{
  width: 44px !important;
  height: 44px !important;
}
