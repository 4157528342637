@import "styles/variables";

.image {
  border-radius: 50%;
  border: 1px solid #E9ECEF;
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.disableToggle {
  &::after {
    display: none !important;
  }
}

.buttonEditAvatar {
  background-color: $light !important;
  width: 38px;
  height: 38px;
  &:hover{
    background-color: $primary !important;
  }
}

.hrColor {
  border-width: 1px;
  color: #E9ECEF !important;
  border-color: #E9ECEF !important;
  opacity: 1 !important;

}

//.info {
//
//    & > div{
//
//      &:first-child{
//        padding-left: 0 !important;
//      }
//
//      &:last-child{
//        padding-right: 0 !important;
//      }
//
//      &:not(:last-child) {
//        @media (min-width: 992px) {
//          border-right: 2px solid #e9ecef;
//        }
//        @media (max-width: 991px) {
//          border-bottom: 2px solid #e9ecef;
//        }
//      }
//    }
//}