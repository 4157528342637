@import "/src/styles/variables";

.accordion {
  font-size: 30px;
  border: none !important;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 4px !important;
}

.accordionItem {
  border-radius: 4px !important;
  border-color: transparent !important;

}

