.iconInfo {
  display: flex;
  margin: 0 0 0 5px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.treatmentPathButton:not(:disabled) {
  cursor: initial !important;
}
