.accordion {
    border: 2px solid #e9ecef;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}


.resetButton {
    margin-right: 24px !important;
}

.accordion {
    border: 2px solid #e9ecef;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.filterButton {
    font-weight: 500 !important;
    overflow: initial !important;
    text-overflow: initial !important;
    @media (max-width: 991.98px) {
        width: 100%;
    }
}

.btn{
    white-space: initial !important;
    overflow: auto !important;
    text-overflow: initial !important;
}

.customOption{
    background: transparent !important;
    color: #003B50 !important;
    cursor: pointer !important;
}

