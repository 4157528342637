.ratingButton {
  background-color: white !important;
  border-width: 2px 0px 2px 2px !important;
  border-color: #e9ecef !important;
  border-style: solid;
  border-radius: 0 !important;
  margin: 0 !important;

  &:first-child {
    border-radius: 4px 0 0 4px !important;
  }
  &:last-child {
    border-radius: 0 4px 4px 0 !important;
    border-width: 2px 2px 2px 2px !important;
  }
  &:hover,
  & img:hover {
    cursor: pointer;
  }
}
.ratingRadio {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
