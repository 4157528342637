.recommendedButton {
  border: 1px solid #e0e0e0 !important;
  font-weight: 500 !important;
}

.recommendedButtonSubmit {
  font-weight: 500 !important;
}

@media (max-width: 991.98px) {
  .recommendedButton {
    width: 100% !important;
  }
}

@media (max-width: 991.98px) {
  .recommendedButtonSubmit {
    width: 100% !important;
  }
}

@media (hover: hover) {
  .resetBtn:hover {
    color: #fff !important;
    background-color: #003b50 !important;
    border-color: #003b50 !important;
  }
}

.resetBtn:active {
  color: #fff !important;
}
