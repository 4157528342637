.wrapper {
  width: 100%;
  max-width: 712px;
  margin-inline: auto;
}

.title {
  letter-spacing: 1.17px;
  @media (max-width: 991.98px) {
    font-size: 29px !important;
    letter-spacing: 0.87px;
    line-height: 130% !important;
  }
}

.containerTitle {
  letter-spacing: 0.57px;
  @media (max-width: 991.98px) {
    font-size: 16px !important;
    letter-spacing: 0.48px;
  }
}

