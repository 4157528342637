.section {
  padding: 48px 60px;
}

@media (max-width: 991.98px) {
  .section {
    padding: 48px 30px;
  }
}

@media (max-width: 767.98px) {
  .section {
    padding: 20px 10px;
  }
}

@media (max-width: 575.98px) {
  .section {
    padding: 20px 14px;
  }
}

.title {
  margin-bottom: 12px;
}

.dateContainer {
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
}

.noEventsHeader {
  font-weight: 600;
  font-size: 19px;
  color: #6c757d;
}

.noEventsContainer {
  border: 1px solid #e9ecef;
  margin-top: 56px !important;
}

.calendarContainer {
  background: white;
  box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.02),
    0px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.04),
    0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.calendarEventsContainer {
  max-height: 230px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    margin-right: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #11c0f2;
    border-radius: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background: #e9ecef;
  }
}

.addEventButton {
  max-height: 439.6px;
  //overflow: auto;
  @media (min-width: 1200px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.showMoreButton {
  @media (max-width: 767.98px) {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .resultsContainer {
    div {
      border-right: none !important;
      &:not(:last-child) {
        border-bottom: 2px solid #e9ecef;
      }
    }
  }
}
