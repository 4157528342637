th {
  border: none;
}

.stats__sortButton {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  line-height: 0px;
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;

  &[data-desc="true"] {
    .sortArrow {
      transform: rotate(180deg);
    }
  }
  :first-child {
    padding-right: 10px;
  }

  :nth-child(2) {
    transition: 0.3s all ease-in-out;
  }
}

.sortArrow {
  &[data-active="true"] {
    > path {
      fill: #ed2369;
    }
  }
}

.collapseContainingDiv td {
  padding: 0 !important;
}
