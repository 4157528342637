@import "src/styles/variables";

.myDoctorAccount {
  color: $primary !important;
}

@media (max-width: 992px) {
  .myDoctorAccount {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 16px !important;
  }
}

.paragraph {
  color: $natural-gray !important;
  line-height: 15.6px !important;
}

.wrapper {
  border: 1px solid #E9ECEF !important;
  border-radius: 4px !important;
}

.badge {
  display: flex !important;
  text-align: center !important;
  font-size: 22px !important;
  border-radius: 4px !important;
  align-items: center !important;
  width: 60px !important;
  height: 40px  !important;
  font-weight: 500 !important;
  padding: 5px !important;
  margin: auto !important;
}

@media (max-width: 992px) {
  .badge {
    font-size: 12px !important;
    max-width: 28px !important;
    height: 16px !important;
  }
}

.ratingContainer {
  width: 210px !important;
}

.PercentIndicatorUpper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 4px 12px !important;
  gap: 10px !important;
  position: absolute !important;
  width: 65px !important;
  height: 29px !important;
  top: 80% !important;
  border-radius: 4px !important;
  transform: translate(-50%, -50%) !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  color: #fff !important;
}

@media (max-width: 992px) {
  .PercentIndicatorUpper {
    top: 90% !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.progressBar {
  height: 8px !important;
}

.OpinionsCounter {
  width: 90% !important;
}

@media (max-width: 992px) {
  .OpinionsCounter {
    display: flex !important;
    flex-direction: column !important;
    text-align: center !important;
    border-top: 1px solid #E9ECEF !important;
    padding-top: 12px !important;
    position: relative !important;
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .containerRatingAndOpinions {
    display: flex !important;
    flex-direction: column !important;
  }
}

.totalRatings {
  font-weight: 600 !important;
}

@media (min-width: 992px) {
  .starsContainer {
    width: 200px !important;
  }
}
