.infWrapper {
  border: 2px solid #11c0f2;
  display: flex;
}

.infContent {
  font-size: 15px;
  font-weight: 600;
  padding: 3rem !important;
  align-items: center;
  color: #003b50;
  display: flex;
}

.infLinks {
  padding: 3rem !important;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 576px) {
  .infContent {
    width: 58.333333%;
  }

  .infLinks {
    width: 41.666667%;
  }
}

@media (max-width: 575px) {
  .infContent {
    width: 100%;
  }

  .infLinks {
    width: 100%;
  }
}
