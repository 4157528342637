@import "styles/variables";

.inputGroup {
  max-width: 150px;

  button {
    height: 38px;
    width: 38px;
    color: $dark !important;
    margin: 0;
    padding: 0;
    border-color: #ADB5BD !important;
    border-width: 1px;
    &:disabled {
      color: #6C757D !important;
      border-color: #ADB5BD !important;
      opacity: 1;
      background: #E9ECEF !important;
    }
    svg{
      height: 24px;
      width: 24px;
    }
  }
  input {
    font-size: 16px;
    width: calc(150px - 2 * 38px);
    text-align: center;
    color: $dark;
    border-color: #ADB5BD;
    border-left: none;
    border-right: none;
    padding: 0;
    border-radius: 0;
    &:disabled {
      color: #6C757D !important;
      border-color: #ADB5BD !important;
      background-color: #E9ECEF !important;
      //color: red !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.inputDate {
  font-size: 16px;
  color: $dark !important;
  height: 38px;
  max-width: 150px;
  border-color: #ADB5BD !important;

  &:disabled {
    color: #6C757D !important;
    border-color: #ADB5BD !important;
    background-color: #E9ECEF !important;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
}
