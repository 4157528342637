@import "../../../styles/_variables.scss";

.filterParagraph {
  letter-spacing: 0.72px !important;
}

.filterButton__unclicked,
.filterButton__clicked {
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  height: 26px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.labelMargin {
  margin-bottom: 6px !important;
}

.customOption {
  z-index: 1 !important;
  position: relative;
  background: white !important;
  color: #003b50 !important;
  cursor: pointer !important;
}

.label {
  color: $natural-gray;
}
