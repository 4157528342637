.dropdownToggle {
  &:hover {
    color: white;
    stroke: white;
    stroke-width: 0.5px;
  }
}

.badge {
  letter-spacing: 0.72px !important;
  padding: 3px 6px;
}
