@import "src/styles/variables";

.starInfo {
  & svg > path {
    fill: $primary;
  }
}

@media (max-width: 768px) {
  .starInfo {
    width: 11px !important;
    height: 11px !important;
  }
}

.starGray {
  & svg > path {
    fill: $gray;
  }
}