.wrapper {
  box-shadow: 0 13px 5px rgba(0, 0, 0, 0.01), 0 8px 5px rgba(0, 0, 0, 0.02),
    0 3px 3px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.04),
    0 0 0 rgba(0, 0, 0, 0.04);
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  @media (max-width: 480px) {
    padding: 24px 0;
  }
}

.section {
  padding: 48px 60px;
  @media (max-width: 480px) {
    padding: 48px 16px;
  }
}

.image {
  object-fit: cover;
  border-radius: 4px;
}

.html {
  a {
    color: #11c0f2 !important;
    font-weight: bold;
  }
}
