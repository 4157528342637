.container {
  max-width: 1140px !important;
  margin-inline: auto;

  iframe {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.loginBtn {
  width: 166px;
}
.signupBtn {
  width: 176px;
}
@media (min-width: 991.98px) {
  .container {
    iframe {
      width: 560px !important;
      height: 315px !important;
    }
  }
}

.header {
  max-width: none;

  span {
    box-decoration-break: clone;
    display: initial;
    margin-left: 10px;

    &:first-child {
      color: white;
      background: var(--colors-primary, #11c0f2);
      font-size: 1.25em;
      line-height: 1.625em;
      box-shadow: 10px 0 0 0 #11c0f2, -10px 0 0 0 #11c0f2;
      @media (min-width: 768px) {
        font-size: 1.875em;
        line-height: 1.625em;
        font-weight: 700;
      }
    }
    &:last-child {
      background-color: #fff;
      color: #004d68;
      font-size: 1.125em;
      line-height: 1.5em;
      box-shadow: 10px 0 0 0 #fff, -10px 0 0 0 #fff;
      @media (min-width: 768px) {
        font-size: 1.375em;
        line-height: 1.625em;
      }
    }
  }
}
.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 245px;

  @media (min-width: 768px) {
    min-height: 520px;
  }
}
.heroText {
  transform: translateY(-25px);

  @media (min-width: 768px) {
    transform: translateY(-360px);
    position: absolute;
    width: 450px;
  }
}
.main {
  background: white;
}
.spacer {
  display: block;
  height: 5px;
  background-color: transparent;
  @media (min-width: 768px) {
    height: 5px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    background-color: white;
  }
}
