.pagination > .active > .page-link {
  background-color: #11c0f2;
  border-color: #E9ECEF;
  font-weight: 400 !important;
}

.pagination > .disabled{
  --bs-pagination-disabled-bg: #E9ECEF !important;
  --bs-pagination-disabled-color: #6C757D !important;
  --bs-pagination-disabled-border-color: #E9ECEF !important;
}

.page-link {
  color: var(--main-text-color);
}

.pagination > .page-item {
  min-width: 38px;
  align-items: center;
  text-align: center;
}