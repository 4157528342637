@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.myCancerContainer {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.avatar {
  width: 160px !important;
  height: 160px;
  background: gray;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 100px !important;
    height: 100px;
  }
}

.dashboardContainter {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 3rem !important;
}

.selectRoleContainer {
  max-width: 712px;
}

@include media-breakpoint-up(md) {
  .dashboardContainter {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }
}

@include media-breakpoint-down(md) {
  .dashboardContainter {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}

