.statuteContainer {
  background-color: #f8f9fa;
  height: 330px;
  overflow: auto;
  border: 2px solid #e9ecef;
  border-radius: 4px;
  &::-webkit-scrollbar {
    width: 4px;
    margin-right: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #11c0f2;
    border-radius: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background: #E9ECEF;
  }
}
