.previews {
  gap: 10px;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
  }
}
