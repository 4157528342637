@import "src/styles/variables";

.badge {
  display: flex !important;
  padding: 0 !important;
  margin: 0 auto !important;
  width: 24px !important;
  height: 16px !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  text-align: center !important;
}