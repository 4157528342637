@import "../../../styles/_variables.scss";

.navLink {
  font-style: normal !important;
  line-height: 24px !important;
}

.navLink:hover {
  color: #ffffff !important;
  background-color: $primary !important;
}