@import "styles/mixins";
@import "styles/variables";

.image {
  @include square(36px);
}

.redCircle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: $secondary;
  border-radius: 50%;
  right: 2px;
  top: 30px;
}
