@import "/src/styles/variables";

@media (max-width: 992px) {
  .title {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.wrapper {
  border: 2px solid $primary;
  border-radius: 4px;
}