@import "../../../styles/_variables.scss";

.userAvatar {
    padding: 0 !important;
    margin: 0 !important;
    img {
        width: 35px;
        height: auto;
        border-radius: 50%;
    }
}

.userName {
    font-weight: 600;
    color: $dark;
}

.whenAdded {
    font-weight: normal;
    color: #adb5bd;
}

.cancerType {
    font-weight: normal;
    color: #adb5bd;
}

.pencilIcon{
    width: 12px;
    height: 12px;
}

.editButton{
    background: $gray;
    transition: .2s all ease-in-out;
    white-space: nowrap !important;
    &:hover{
        color: $dark;
    }
}
