@import "styles/variables";

.icon {
  margin-right: 5px;
}

.button {
  &> button {
    height: 40px;
    width: 40px;
  }
}

.inputDiv {
  @media (min-width: 992px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
}

.input {
  //height: 100%;
  font-size: 16px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px !important;
  border-color: #ADB5BD;
  color: $dark;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
  &::-webkit-input-placeholder {
    text-align: right;
  }

  &:-moz-placeholder {
    text-align: right;
  }

  &::-moz-placeholder {
    text-align: right;
  }

  &:-ms-input-placeholder {
    text-align: right;
  }



}

.deleteBrother {
  &~div{
    display: none;
  }
}

.dropdownToggle {
  &::after {
    display: none !important;
  }
}

.nameLabel {
  @media (min-width: 992px) {
    min-width: 240px;
  }
  @media (max-width: 991px) {
    min-width: 0;
  }
}
.disabledDiv {
  min-height: 100px;
  padding: 6px 12px;
  background-color: #e9ecef;
  opacity: 1;
  font-size: 16px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px !important;
  border-color: #ADB5BD;
}

.radius {
  border-radius: 4px;
}