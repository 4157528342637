.myCancerContainer
{   
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

@media (max-width: 991.98px) {
    .title {
        font-size: 16px !important;

    }
}
