@import "/src/styles/variables";

.modalImage {
  display: block;
  margin: 0 auto 16px;
  max-width: 100%;
  max-height: 90%;
  max-height: calc(100% - 42px);
}

.modalHeader
{
  padding-top: 28.5px !important;
  padding-bottom: 28.5px !important;
}
.modalMenu {
  width: 30%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 576px) {
    width: 100%;
  }
}

.modalDescription 
{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.06em;
  color: #ADB5BD;
}

.labelMargin
{
  margin-bottom: 6px !important;
}

.customOption{
  z-index: 1 !important;
  position: relative;
  background: white !important;
  color: #003B50 !important;
  cursor: pointer !important;
 
}

.inputGroup {
  max-width: 150px;

  button {
    height: 38px;
    width: 38px;
    color: $dark !important;
    margin: 0;
    padding: 0;
    border-color: #ADB5BD !important;
    border-width: 1px;
    &:disabled {
      color: #6C757D !important;
      border-color: #ADB5BD !important;
      opacity: 1;
      background: #E9ECEF !important;
    }
    svg{
      height: 24px;
      width: 24px;
    }
  }
  input {
    font-size: 16px;
    width: calc(150px - 2 * 38px);
    text-align: center;
    color: $dark;
    border-color: #ADB5BD;
    border-left: none;
    border-right: none;
    padding: 0;
    border-radius: 0;
    &:disabled {
      color: #6C757D !important;
      border-color: #ADB5BD !important;
      background-color: #E9ECEF !important;
      //color: red !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
