.article {

  & > div {
    &:not(:last-child) {
      @media (min-width: 992px) {
        border-right: 2px solid #e9ecef;
      }
      @media (max-width: 991px) {
        border-bottom: 2px solid #e9ecef;
      }
    }
  }
}