@import "styles/variables";

.button {
  width: 400px;
  &> button {
    height: 40px;
    width: 40px;
  }
}

.buttonSize {
  height: 40px;
  width: 40px;
}

.nameLabel {
  min-width: 200px;
}

.arrowUp {
  svg {
    transform: rotate(270deg);
    transition: transform  200ms;
  }
}

.arrowDown {
  svg {
    transform: rotate(90deg);
    transition: transform  200ms;
  }
}

.progresBars {
  div {
    background-color:  #E9ECEF;
  }
  width: 100px;
  height: 8px !important;
  border-radius: 4px;
}