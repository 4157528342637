.breadcrumbButton {
  border: none;
  background-color: transparent;
  color: #11c0f2;
  font-size: 14px;

  &:hover {
    color: #29c6f3;
  }
}
