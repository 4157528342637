@media (max-width: 991.98px) {
  .title {
    font-size: 16px !important;
  }
}

.infoButton {
  width: 100%;
  @media (min-width: 1400px) {
    & {
      width: auto;
    }
  }
}
