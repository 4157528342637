@import "../../../styles/_variables.scss";

.badge {
  padding: 3px 6px 3px 6px !important;
}

.button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}

.xMark {
  width: 10px !important;
  height: 10px !important;
  margin: 0 0 0 2px !important;
  padding: 0 !important;
  align-self: flex-start !important;
}