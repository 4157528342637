@import "./variables";
html,
body {
  padding: 0;
  margin: 0;
  background-color: $background !important;
}

//body {
//  font-family: "Lato", sans-serif;
//}

a {
  color: inherit;
  text-decoration: none !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tox-editor-container {
  width: auto !important;
  height: 100% !important;
}


@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
