@import "../../../styles/_variables.scss";

.navigation {
  min-width: 240px;
  width: 240px;
  min-height: 100vh;
  background-color: #1d2327;

  & a {
    color: #fff;
  }
}

.navBrand {
  width: 30px !important;
  padding-left: 10px !important;
}

.navBarSearch {
  margin-left: 252px;
  width: 550px;
  margin-right: 16px;
  @media (max-width: 1199.98px) {
    margin-left: 200px;
  }
  @media (max-width: 991.98px) {
    margin-left: 180px;
    width: 80%;
  }
  @media (max-width: 767.98px) {
    margin-left: 140px;
    width: 60%;
  }
}

.navBarSearchSmaller {
  width: 100%;
}

.searchInputWrapper {
  background: white;
  border: 1px solid #adb5bd;

  input {
    border: 0;
    outline: 0 !important;
  }

  span {
    path {
      stroke: #adb5bd;
    }
  }
}

.searchInputWrapper {
  width: 100%;
}

.redDotMessage {
  width: 6px;
  height: 6px;
  background-color: $secondary;
  border-radius: 50%;
  position: absolute;
  right: 99px;
  top: 36px;
  @media (max-width: 991.98px) {
    right: 92px;
    top: 22px;
  }
  @media (max-width: 575.98px) {
    right: 91px;
  }
}

.redDotBell {
  width: 6px;
  height: 6px;
  background-color: $secondary;
  border-radius: 50%;
  position: absolute;
  right: 59px;
  top: 36px;
  @media (max-width: 991.98px) {
    right: 56px;
    top: 23px;
  }
  @media (max-width: 575.98px) {
    right: 54px;
  }
}

.hooverIconLink {
  path {
    transition: 0.2s stroke ease-in-out;
    stroke: #003b50;
  }

  :hover {
    path {
      stroke: #11c0f2 !important;
    }
  }
}

.dropdownRight {
  right: 0 !important;
  left: auto !important;
  width: 344px;
}

.dropdownButton {
  padding: 0;
  path {
    transition: 0.2s stroke ease-in-out;
    fill: #003b50;
  }

  &:hover {
    path {
      fill: #fff;
      background-color: $dark;
    }
  }
}

.navBar {
  z-index: 4;
  height: 98px;
  width: 100%;
  background-color: #fff;
  position: sticky !important;
  top: 0;
  @media (max-width: 991.98px) {
    height: 70px;
  }
}

.dItem {
  path {
    fill: #11c0f2;
    stroke: #11c0f2;
    stroke-width: 0.5px;
  }

  &:hover,
  &:active {
    background-color: #f8f9fa !important;
  }
}

.dItemFirst {
  path {
    stroke: #11c0f2;
    fill: #11c0f2;
  }

  &:hover,
  &:active {
    background-color: #f8f9fa !important;
  }
}
