@import '../../../styles/_variables.scss';

.feedPosts{
    background: white;
    box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 24px 0;
    justify-content: center;
}

.feedPostTags{
    color: $primary;
    font-size: 12px;
    span:not(:last-child){
        border-right: 2px solid #E9ECEF;
    }
}

.feedPostContent{
    p{
        white-space: pre-wrap !important;
    }
    a{
        color: #11C0F2 !important;
    }
}

.addPostAvatar{
    padding: 0 !important;
    margin: 0 !important;
    width: 5% !important;
    img{
        width: 35px !important;
        height: auto;
        border-radius: 50%;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.addPostInput{
    width: 95% !important;
    input{
        width: 100%;
    }
}

.postImage{
    img{
        background: $gray;
        object-fit: contain !important;
        object-position: center !important;
        width: 100%;
    }
}