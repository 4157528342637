@import "../../../styles/_variables.scss";

.formSubmit:disabled {
  background: $gray !important;
  font-weight: 500 !important;
  border: 0 !important;
  color: #ADB5BD !important;
}

.postControlError{
  color: #dc3545;
}

.postContentImageWrapper {
  .urlContentWrapper {
    background: #f8f9fa;
    .urlContentTitle {
      font-size: 12px;
    }
  }
  .postContentImage {
    width: 100%;
    position: relative;
    .postContentImageCloseButton {
      position: absolute;
      top: -19px;
      right: 10px;
      width: 38px;
      height: 38px;
      background: white;
      padding: 10px;
    }
    img {
      object-fit: contain !important;
      object-position: center !important;
      width: 100%;
      border-radius: 4px;
    }
  }
  .urlImage {
    img {
      height: 300px;
    }
  }
}

.backButton {
  border: 2px solid $gray !important;
  color: black !important;
}

.postContentInput {
  resize: vertical !important;

  p {
    width: 100%;
    height: 100%;
  }
}

.customOption{
  background: transparent !important;
  color: #003B50 !important;
  cursor: pointer !important;
}
