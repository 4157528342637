@import "styles/variables";

.divInOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.buttonInOptions {
  text-align: left;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    color: $primary !important;
  }
  @media (max-width: 991.98px) {
    width: 100%;
  }
}