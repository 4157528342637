.editTopicBtn {
  background: none;
  border: none;
  width: 38px;
  height: 38px !important;
  padding: 7px;
  & img {
    width: 24px;
    height: 24px;
  }
}
.topic {
  padding: 16px;
  background: none;
  border-radius: 4px;
  border: 2px solid var(--natural-light-gray, #e9ecef);
  text-align: left;
}
