.map-modal-container {
  & .modal-content {
    height: 80vh;
  }
}

.modal:nth-of-type(even) {
  --bs-modal-zindex: 1054 !important;
}

.modal-backdrop.show:nth-of-type(even) {
  --bs-modal-zindex: 1055 !important;
}
