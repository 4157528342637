.custom-calendar .react-calendar {
    font-size: 16px !important;
    width: 100% !important;
    max-width: 255px !important;
    height: 280px !important;
    border: none;
    padding: 0px !important;
    border-left: 1px solid #e9ecef !important;
    border-right: 1px solid #e9ecef !important;
}

.custom-calendar .react-calendar__decade-view__years__year {
    font-size: 12px !important;
    color: #003b50 !important;
}

.custom-calendar .react-calendar__navigation {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 5px 0 !important;
}

.custom-calendar .react-calendar__tile {
    max-width: 100% !important;
    padding: 0px !important;
}

.custom-calendar .react-calendar__viewContainer {
    width: 100% !important;
    padding: 0px !important;
    font-size: 12px !important;
    padding: 0px !important;
}

.custom-calendar .react-calendar__navigation button {
    min-width: 44px !important;
}

.custom-calendar .react-calendar__navigation__label {
    margin: 0 !important;
    text-align: center !important;
    font-size: 12px !important;
    font-weight: bold !important;
    color: #333 !important;
}

.custom-calendar .react-calendar__navigation__label__labelText {
    font-size: 14px !important;
}

.custom-calendar .react-calendar__month-view__days__day--weekend {
    color: #ed2369 !important;
}

.custom-calendar .react-calendar__month-view__weekdays {
    font-size: 10px !important;
}

.custom-calendar .react-calendar__month-view__days__day {
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-calendar .react-calendar__navigation__label {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #003b50 !important;
}

.custom-calendar .react-calendar__navigation__arrow {
    font-size: 24px !important;
    color: #003b50 !important;
    padding: 0 !important;
}

.custom-calendar .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 0 !important;
    padding: 0 !important;
    align-items: center;
    justify-items: center;
}

.custom-calendar .react-calendar__century-view__decades__decade {
    font-size: 14px !important;
    color: #003b50 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px !important;
    width: 100% !important;
}

.custom-calendar .react-calendar__decade-view__years {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 0 !important;
    padding: 0 !important;
    align-items: center;
    justify-items: center;
}

.custom-calendar .react-calendar__decade-view__years__year {
    font-size: 14px !important;
    color: #003b50 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    width: 100% !important;
}

.custom-calendar .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 0 !important;
    padding: 0 !important;
    align-items: center;
    justify-items: center;
    color: #003b50 !important;
}

.custom-calendar .react-calendar__year-view__months__month {
    font-size: 14px !important;
    color: #003b50 !important;
    display: flex;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    height: 50px !important;
    width: 100% !important;
}

.custom-calendar .react-calendar__year-view__months abbr {
    overflow: visible !important;
    font-size: 14px !important;
    display: block !important;
    width: 100% !important;
    color: #003b50 !important;
}

.custom-calendar .react-calendar__tile abbr {
    overflow: visible !important;
    font-size: 14px !important;
    display: block !important;
}

.custom-calendar .react-calendar__tile--active abbr::after {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    top: -5px !important;
    left: -5px !important;
    background-color: #003b50 !important;
    border: none !important;
}

.custom-calendar .react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--weekend) {
    color: #003b50 !important;
}
