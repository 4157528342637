@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.RoleTileContainer
{
    border-radius: 4px;
    height: 240px;
}

@include media-breakpoint-up(sm) {
    .firstAndLastNameMarginLeft
{
    margin-left: 12px !important;
}

.firstAndLastNameMarginRight
{
    margin-right: 12px !important;
}

}